<template>
    <div class="w-full overflow-hidden p-4">
        <form @submit.prevent="handleSubmit()">
            <v-card class="ma-4 cardBoxShadow rounded-lg">
                <v-row no-gutters class="px-4 py-10">
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <div class="fsize14 font-weight-bold primaryColor pb-4">{{ isEdit ? 'Edit User' : 'Add New User' }}
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="5">
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="d-flex justify-space-between pr-10">
                                    <label class="text-sm">User ID</label>
                                </div>
                                <v-text-field :class="{ 'error--text v-input--is-focused': submitted && userID == '' }"
                                    hide-details maxlength="15" outlined dense v-model="userID"></v-text-field>
                                <div class="error-msg">
                                    <span v-if="submitted && userID == ''">User ID is required</span>
                                </div>
                            </div>
                        </div>
                        <!-- 2 -->
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="d-flex justify-space-between pr-10">
                                    <label class="text-sm">First Name</label>
                                </div>
                                <v-text-field :class="{ 'error--text v-input--is-focused': submitted && firstName == '' }"
                                    hide-details maxlength="50" outlined dense v-model="firstName"></v-text-field>
                                <div class="error-msg">
                                    <span v-if="submitted && firstName == ''">First Name is required</span>
                                </div>
                            </div>
                        </div>
                        <!-- 3 -->
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="d-flex justify-space-between pr-10">
                                    <label class="text-sm">Last Name</label>
                                </div>
                                <v-text-field hide-details maxlength="50" outlined dense v-model="lastName"></v-text-field>
                                <div class="error-msg"></div>
                            </div>
                        </div>
                        <!-- 4 -->
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="d-flex justify-space-between pr-10">
                                    <label class="text-sm">E-Mail ID</label>
                                </div>
                                <v-text-field :class="{ 'error--text v-input--is-focused': submitted && !isValidEmail() }"
                                    hide-details maxlength="50" outlined dense v-model="emailID"></v-text-field>
                                <div class="error-msg">
                                    <span v-if="submitted && !isValidEmail()">Enter Valid E-Mail ID</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="w-100">
                                <div class="d-flex justify-space-between pr-10">
                                    <label class="text-sm">OMS</label>
                                </div>
                                <v-text-field
                                    :class="{ 'error--text v-input--is-focused': (submitted && oms == '' || submitted && oms.length < 3) }"
                                    hide-details maxlength="5" outlined dense v-model="oms"
                                    @input="oms ? oms = oms.toUpperCase() : ''"></v-text-field>
                                <div class="error-msg">
                                    <span v-if="submitted && oms == ''">OMS is required</span>
                                    <span v-else-if="submitted && oms.length < 3">Minimum 3 characters required</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex gap-4">
                            <div class="d-flex w-half">
                                <div class="w-100">
                                    <div class="d-flex justify-space-between pr-10">
                                        <label class="text-sm">PAN Number</label>
                                    </div>
                                    <v-text-field :class="{ 'error--text v-input--is-focused': submitted && !isValidPAN() }"
                                        hide-details class="w-full" maxlength="10" outlined dense v-model="pan"
                                        @input="pan ? pan = pan.toUpperCase() : ''"
                                        @keypress="keyPressAlphaNumeric($event)"></v-text-field>
                                    <div class="error-msg">
                                        <span v-if="submitted && !isValidPAN()">Enter Valid PAN Number</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-half">
                                <div class="w-100">
                                    <div class="d-flex justify-space-between pr-10">
                                        <label class="text-sm">Mobile No</label>
                                    </div>
                                    <v-text-field
                                        :class="{ 'error--text v-input--is-focused': submitted && !isValidMobile() }"
                                        type="number" hide-details maxlength="10" outlined dense v-model="mobile"
                                        @keypress="maxLengthTen($event)"></v-text-field>
                                    <div class="error-msg">
                                        <span v-if="submitted && !isValidMobile()">Enter Valid Mobile Number</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex gap-4 w-full" v-if="!isEdit">
                            <div class="d-flex w-half">
                                <div class="w-100">
                                    <div class="d-flex justify-space-between pr-10">
                                        <label class="text-sm">Marital Status</label>
                                    </div>
                                    <v-autocomplete
                                        :class="{ 'error--text v-input--is-focused': submitted && maritalStatus == 'options' && !isEdit }"
                                        hide-details outlined dense :items="['Single', 'Married']"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        v-model="maritalStatus"></v-autocomplete>
                                    <div class="error-msg">
                                        <span v-if="submitted && maritalStatus == 'options' && !isEdit">Marital Status is
                                            required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex w-half">
                                <div class="w-100">
                                    <div class="d-flex justify-space-between pr-10">
                                        <label class="text-sm">Gender</label>
                                    </div>
                                    <v-autocomplete
                                        :class="{ 'error--text v-input--is-focused': submitted && gender == 'options' && !isEdit }"
                                        hide-details outlined dense :items="['Male', 'Female']"
                                        :menu-props="{ bottom: true, offsetY: true }" v-model="gender"></v-autocomplete>
                                    <div class="error-msg">
                                        <span v-if="submitted && gender == 'options' && !isEdit">Gender is required</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-center my-4 gap-4">
                            <p class="deatailsHead whitespace-nowrap min-w-100px text-sm mb-0">Enabled</p>
                            <v-switch label="Switch" dense :ripple="false" v-model="enabledSwitch"></v-switch>
                        </div>

                        <div class="d-flex align-center my-4 gap-4">
                            <p class="deatailsHead whitespace-nowrap min-w-100px text-sm mb-0">Email Verified</p>
                            <v-switch label="Switch" dense :ripple="false" v-model="emailVerifiedSwitch"></v-switch>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <!-- <div class="d-flex w-100 justify-end"><a class="fsize14 underline-none">Reset</a></div> -->
                    </v-col>
                </v-row>
            </v-card>
            <div class="mt-5 pa-4 d-flex justify-end">
                <v-btn height="40" width="96" depressed outlined color="grey--text" class=" mr-3 text-capitalize"
                    @click="goBack()">Cancel</v-btn>
                <v-btn type="submit" height="40" width="96" depressed color="primary"
                    class="text-capitalize fsize16">Submit</v-btn>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            userID: '',
            emailID: '',
            mobile: '',
            pan: '',
            maritalStatus: 'Single',
            gender: 'Male',
            submitted: false,
            currentData: '',
            enabledSwitch: false,
            emailVerifiedSwitch: false,
            isEdit: false,
            oms: ''
        }
    },
    methods: {
        async handleSubmit() {
            this.submitted = true
            if (this.validateForm() && this.isEdit) {
                let json = {
                    "username": this.userID ? this.userID.toUpperCase() : this.userID,
                    "enabled": this.enabledSwitch,
                    "emailVerified": this.emailVerifiedSwitch,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "email": this.emailID,
                    "attributes": {
                        "mobile": [this.mobile],
                        "pan": [this.pan],
                        "ucc": [this.userID ? this.userID.toUpperCase() : this.userID],
                        "oms": [this.oms]
                    }
                }
                await this.$store.dispatch('user/updateUserDetails', json)
            } else if (this.validateForm() && !this.isEdit) {
                let json = {
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "username": this.userID ? this.userID.toUpperCase() : this.userID,
                    "email": this.emailID,
                    "enabled": true,
                    "emailVerified": true,
                    "attributes": {
                        "ucc": [this.userID ? this.userID.toUpperCase() : this.userID],
                        "mobile": [this.mobile],
                        "pan": [this.pan],
                        "maritalStatus": this.maritalStatus ? [this.maritalStatus.toUpperCase()] : [this.maritalStatus],
                        "gender": this.gender ? [this.gender.toUpperCase().charAt(0)] : [this.gender],
                        "oms": [this.oms]
                    }
                }
                await this.$store.dispatch('user/addUserKeyCloak', json)
            }
        },
        goBack() {
            this.$store.commit('user/setUserDetails', [])
            this.$router.push('/userLogs?tab=User Details')
        },
        setEditData() {
            if (this.currentData) {
                this.userID = this.currentData['username']
                this.firstName = this.currentData['firstName']
                this.lastName = this.currentData['lastName']
                this.emailID = this.currentData['email']
                this.enabledSwitch = this.currentData['enabled']
                this.emailVerifiedSwitch = this.currentData['emailVerified']
                this.mobile = this.currentData['attributes']['mobile'][0]
                this.pan = this.currentData['attributes']['pan'][0]
                this.oms = this.currentData['attributes']['oms'][0]
            }
        },
        handleEnabledSwitch() {
            this.enabledSwitch = !this.enabledSwitch
        },
        handleEmailVerifiedSwitch() {
            this.emailVerifiedSwitch = !this.emailVerifiedSwitch
        },
        validateForm() {
            if (this.isEdit) {
                return this.userID != '' && this.firstName != '' && this.oms != '' && this.oms.length >= 3 && this.isValidPAN() && this.isValidMobile() && this.isValidEmail()
            } else {
                return this.userID != '' && this.firstName != '' && this.maritalStatus != 'options' && this.gender != 'options' && this.oms != '' && this.oms.length >= 3 && this.isValidPAN() && this.isValidMobile() && this.isValidEmail()
            }
        },
        maxLengthTen(event) {
            this.mobile.length >= 10 ? event.preventDefault() : ''
        },
        keyPressAlphaNumeric(event) {
            var inp = String.fromCharCode(event.keyCode);
            if (event.keyCode != 13) {
                if (/[a-zA-Z0-9]/.test(inp)) {
                    return true;
                } else {
                    event.preventDefault();
                    return false;
                }
            }
        },
        isValidMobile() {
            const panPattern = /^[6-9]\d{9}$/gi;
            const str = this.mobile;
            return panPattern.test(str)
        },
        isValidPAN() {
            const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            return panPattern.test(this.pan)
        },
        isValidEmail() {
            const panPattern = /\S+@\S+\.\S+/;
            return panPattern.test(this.emailID);
        },
    },
    created() {
        if (localStorage.getItem('currentEditData')) {
            let currentEditData = JSON.parse(localStorage.getItem('currentEditData'))
            if (currentEditData && this.$route.query.edit == 'true' && currentEditData != '') {
                this.isEdit = true
                this.currentData = currentEditData
                this.setEditData()
            }
        }
    },
}
</script>

